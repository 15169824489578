var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "invoice-create-page main-content" }, [
    _c("div", { staticClass: "page-header" }, [
      _c("h3", { staticClass: "page-title" }, [
        _vm._v(_vm._s(_vm.$tc("settings.setting", 1)))
      ]),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              {
                attrs: { slot: "item-title", to: "/admin/dashboard" },
                slot: "item-title"
              },
              [_vm._v(_vm._s(_vm.$t("general.home")))]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              {
                attrs: {
                  slot: "item-title",
                  to: "/admin/settings/user-profile"
                },
                slot: "item-title"
              },
              [_vm._v(_vm._s(_vm.$tc("settings.setting", 2)))]
            )
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row settings-container" }, [
      _c("div", { staticClass: "col-lg-3 settings-sidebar-container" }, [
        _c(
          "ol",
          { staticClass: "settings-sidebar" },
          _vm._l(_vm.menuItems, function(menuItem, index) {
            return _c(
              "li",
              { key: index, staticClass: "settings-menu-item" },
              [
                _c(
                  "router-link",
                  {
                    class: [
                      "link-color",
                      { "active-setting": _vm.hasActiveUrl(menuItem.link) }
                    ],
                    attrs: { to: menuItem.link }
                  },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "setting-icon",
                      attrs: { icon: [menuItem.iconType, menuItem.icon] }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "menu-title ml-3" }, [
                      _vm._v(_vm._s(_vm.$t(menuItem.title)))
                    ])
                  ],
                  1
                )
              ],
              1
            )
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-lg-9" },
        [
          _c(
            "transition",
            { attrs: { name: "fade", mode: "out-in" } },
            [_c("router-view")],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }