var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("textarea", {
    directives: [
      {
        name: "model",
        rawName: "v-model",
        value: _vm.inputValue,
        expression: "inputValue"
      }
    ],
    staticClass: "text-area-field",
    class: [
      "base-text-area",
      { invalid: _vm.isFieldValid, disabled: _vm.disabled },
      _vm.inputClass
    ],
    attrs: {
      rows: _vm.rows,
      cols: _vm.cols,
      disabled: _vm.disabled,
      placeholder: _vm.placeholder
    },
    domProps: { value: _vm.inputValue },
    on: {
      input: [
        function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.inputValue = $event.target.value
        },
        _vm.handleInput
      ],
      change: _vm.handleChange,
      keyup: _vm.handleKeyupEnter
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }