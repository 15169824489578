var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h3", { staticClass: "text-center" }, [
      _vm._v("Great, Your email is verified, we are logging in now")
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "text-center" },
      [
        _vm.loading
          ? _c("font-awesome-icon", {
              staticClass: "fa-spin fa-2x",
              attrs: { icon: "spinner" }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }