import mutations from './mutations'
import * as actions from './actions'
import * as getters from './getters'

const initialState = {
    totalPayables: 0,
    payables: [],
    selectAllField: false,
    selectedPayables: [],
    selectedSupplier: null
}

export default {
    namespaced: true,

    state: initialState,

    getters: getters,

    actions: actions,

    mutations: mutations
}
