var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card-body" }, [
    _c(
      "form",
      {
        attrs: { action: "" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.next()
          }
        }
      },
      [
        _c("p", { staticClass: "form-title" }, [
          _vm._v(_vm._s(_vm.$t("wizard.account_info")))
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "form-desc" }, [
          _vm._v(_vm._s(_vm.$t("wizard.account_info_desc")))
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row mb-4" },
          [
            _c("div", { staticClass: "col-md-6" }, [
              _c("label", { staticClass: "form-label" }, [
                _vm._v(
                  _vm._s(_vm.$tc("settings.account_settings.profile_picture"))
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "image-upload-box avatar-upload",
                  attrs: { id: "pick-avatar" }
                },
                [
                  _c(
                    "div",
                    { staticClass: "overlay" },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "white-icon",
                        attrs: { icon: "camera" }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.previewAvatar
                    ? _c("img", {
                        staticClass: "preview-logo",
                        attrs: { src: _vm.previewAvatar }
                      })
                    : _c(
                        "div",
                        { staticClass: "upload-content" },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "upload-icon",
                            attrs: { icon: "cloud-upload-alt" }
                          }),
                          _vm._v(" "),
                          _c("p", { staticClass: "upload-text" }, [
                            _vm._v(
                              " " + _vm._s(_vm.$tc("general.choose_file")) + " "
                            )
                          ])
                        ],
                        1
                      )
                ]
              )
            ]),
            _vm._v(" "),
            _c("avatar-cropper", {
              attrs: {
                labels: { submit: "submit", cancel: "Cancel" },
                "cropper-options": _vm.cropperOptions,
                "output-options": _vm.cropperOutputOptions,
                "output-quality": 0.8,
                "upload-handler": _vm.cropperHandler,
                trigger: "#pick-avatar"
              },
              on: { changed: _vm.setFileObject, error: _vm.handleUploadError }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c("label", { staticClass: "form-label" }, [
                _vm._v(_vm._s(_vm.$t("wizard.name")))
              ]),
              _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
              _vm._v(" "),
              _c("base-input", {
                attrs: {
                  invalid: _vm.$v.profileData.name.$error,
                  type: "text",
                  name: "name"
                },
                on: {
                  input: function($event) {
                    return _vm.$v.profileData.name.$touch()
                  }
                },
                model: {
                  value: _vm.profileData.name,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.profileData,
                      "name",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "profileData.name"
                }
              }),
              _vm._v(" "),
              _vm.$v.profileData.name.$error
                ? _c("div", [
                    !_vm.$v.profileData.name.required
                      ? _c("span", { staticClass: "text-danger" }, [
                          _vm._v(_vm._s(_vm.$tc("validation.required")))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.$v.profileData.name.minLength
                      ? _c("span", { staticClass: "text-danger" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$tc(
                                  "validation.name_min_length",
                                  _vm.$v.profileData.name.$params.minLength.min,
                                  {
                                    count:
                                      _vm.$v.profileData.name.$params.minLength
                                        .min
                                  }
                                )
                              ) +
                              " "
                          )
                        ])
                      : _vm._e()
                  ])
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c("label", { staticClass: "form-label" }, [
                _vm._v(_vm._s(_vm.$t("wizard.email")))
              ]),
              _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
              _vm._v(" "),
              _c("base-input", {
                attrs: {
                  invalid: _vm.$v.profileData.email.$error,
                  type: "text",
                  name: "email"
                },
                on: {
                  input: function($event) {
                    return _vm.$v.profileData.email.$touch()
                  }
                },
                model: {
                  value: _vm.profileData.email,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.profileData,
                      "email",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "profileData.email"
                }
              }),
              _vm._v(" "),
              _vm.$v.profileData.email.$error
                ? _c("div", [
                    !_vm.$v.profileData.email.required
                      ? _c("span", { staticClass: "text-danger" }, [
                          _vm._v(_vm._s(_vm.$tc("validation.required")))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.$v.profileData.email.email
                      ? _c("span", { staticClass: "text-danger" }, [
                          _vm._v(_vm._s(_vm.$tc("validation.email_incorrect")))
                        ])
                      : _vm._e()
                  ])
                : _vm._e()
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c("label", { staticClass: "form-label" }, [
                _vm._v(_vm._s(_vm.$t("wizard.password")))
              ]),
              _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
              _vm._v(" "),
              _c("base-input", {
                attrs: {
                  invalid: _vm.$v.profileData.password.$error,
                  type: "password",
                  name: "password"
                },
                on: {
                  input: function($event) {
                    return _vm.$v.profileData.password.$touch()
                  }
                },
                model: {
                  value: _vm.profileData.password,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.profileData,
                      "password",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "profileData.password"
                }
              }),
              _vm._v(" "),
              _vm.$v.profileData.password.$error
                ? _c("div", [
                    !_vm.$v.profileData.password.required
                      ? _c("span", { staticClass: "text-danger" }, [
                          _vm._v(_vm._s(_vm.$tc("validation.required")))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.$v.profileData.password.minLength
                      ? _c("span", { staticClass: "text-danger" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$tc(
                                  "validation.password_min_length",
                                  _vm.$v.profileData.password.$params.minLength
                                    .min,
                                  {
                                    count:
                                      _vm.$v.profileData.password.$params
                                        .minLength.min
                                  }
                                )
                              ) +
                              " "
                          )
                        ])
                      : _vm._e()
                  ])
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c("label", { staticClass: "form-label" }, [
                _vm._v(_vm._s(_vm.$t("wizard.confirm_password")))
              ]),
              _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
              _vm._v(" "),
              _c("base-input", {
                attrs: {
                  invalid: _vm.$v.profileData.confirm_password.$error,
                  type: "password",
                  name: "confirm_password"
                },
                on: {
                  input: function($event) {
                    return _vm.$v.profileData.confirm_password.$touch()
                  }
                },
                model: {
                  value: _vm.profileData.confirm_password,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.profileData,
                      "confirm_password",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "profileData.confirm_password"
                }
              }),
              _vm._v(" "),
              _vm.$v.profileData.confirm_password.$error
                ? _c("div", [
                    !_vm.$v.profileData.confirm_password.sameAsPassword
                      ? _c("span", { staticClass: "text-danger" }, [
                          _vm._v(
                            _vm._s(_vm.$tc("validation.password_incorrect"))
                          )
                        ])
                      : _vm._e()
                  ])
                : _vm._e()
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c(
          "base-button",
          {
            staticClass: "pull-right mt-4",
            attrs: {
              loading: _vm.loading,
              icon: "save",
              color: "theme",
              type: "submit"
            }
          },
          [_vm._v("\n      " + _vm._s(_vm.$t("wizard.save_cont")) + "\n    ")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }