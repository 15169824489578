var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.clickOutsideMenu,
          expression: "clickOutsideMenu"
        }
      ],
      staticClass: "search-select"
    },
    [
      _c(
        "div",
        { staticClass: "activator", on: { click: _vm.toggleSearchMenu } },
        [_vm._t("activator")],
        2
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.showMenu
          ? _c(
              "div",
              {
                staticClass: "selector-menu",
                class: { "selector-menu-above": _vm.isAbove }
              },
              [_vm._t("default")],
              2
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }