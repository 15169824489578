<template>
  <div>
    <h3 class="text-center">Great, Your email is verified, we are logging in now</h3>
    <div class="text-center">
      <font-awesome-icon
        v-if="loading"
        icon="spinner"
        class="fa-spin fa-2x"
      />
    </div>
  </div>
</template>
<script>

export default{
  data () {
    return {
      loading: true
    }
  },
  created () {
    setTimeout(() => {
      window.toastr['success'](this.$t('email.verified'))
      window.location.href = '/admin/dashboard'
    }, 10 * 20)
  }
}
</script>
