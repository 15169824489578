var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      attrs: { action: "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.register()
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("label", { staticClass: "form-label" }, [
            _vm._v(_vm._s(_vm.$t("register.company_name")))
          ]),
          _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
          _vm._v(" "),
          _c("base-input", {
            attrs: {
              invalid:
                _vm.$v.registration.company.$error || _vm.errors.company != "",
              type: "text",
              name: "name"
            },
            on: {
              input: function($event) {
                return _vm.$v.registration.company.$touch()
              }
            },
            model: {
              value: _vm.registration.company,
              callback: function($$v) {
                _vm.$set(
                  _vm.registration,
                  "company",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "registration.company"
            }
          }),
          _vm._v(" "),
          _vm.$v.registration.company.$error
            ? _c("div", [
                !_vm.$v.registration.company.required
                  ? _c("span", { staticClass: "text-danger" }, [
                      _vm._v(_vm._s(_vm.$tc("validation.required")))
                    ])
                  : _vm._e()
              ])
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("label", { staticClass: "form-label" }, [
            _vm._v(_vm._s(_vm.$t("register.name")))
          ]),
          _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
          _vm._v(" "),
          _c("base-input", {
            attrs: {
              invalid: _vm.$v.registration.name.$error,
              type: "text",
              name: "name"
            },
            on: {
              input: function($event) {
                return _vm.$v.registration.name.$touch()
              }
            },
            model: {
              value: _vm.registration.name,
              callback: function($$v) {
                _vm.$set(
                  _vm.registration,
                  "name",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "registration.name"
            }
          }),
          _vm._v(" "),
          _vm.$v.registration.name.$error
            ? _c("div", [
                !_vm.$v.registration.name.required
                  ? _c("span", { staticClass: "text-danger" }, [
                      _vm._v(_vm._s(_vm.$tc("validation.required")))
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.$v.registration.name.minLength
                  ? _c("span", { staticClass: "text-danger" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$tc(
                              "validation.name_min_length",
                              _vm.$v.registration.name.$params.minLength.min,
                              {
                                count:
                                  _vm.$v.registration.name.$params.minLength.min
                              }
                            )
                          ) +
                          " "
                      )
                    ])
                  : _vm._e()
              ])
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("label", { staticClass: "form-label" }, [
            _vm._v(_vm._s(_vm.$t("register.email")))
          ]),
          _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
          _vm._v(" "),
          _c("base-input", {
            attrs: {
              invalid:
                _vm.$v.registration.email.$error || _vm.errors.email != "",
              type: "text",
              name: "email"
            },
            on: {
              input: function($event) {
                return _vm.$v.registration.email.$touch()
              }
            },
            model: {
              value: _vm.registration.email,
              callback: function($$v) {
                _vm.$set(
                  _vm.registration,
                  "email",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "registration.email"
            }
          }),
          _vm._v(" "),
          _vm.$v.registration.email.$error || _vm.errors.email != ""
            ? _c("div", [
                !_vm.$v.registration.email.isUnique
                  ? _c("span", { staticClass: "text-danger" }, [
                      _vm._v(_vm._s(_vm.$tc("validation.email_already_taken")))
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.$v.registration.email.required
                  ? _c("span", { staticClass: "text-danger" }, [
                      _vm._v(_vm._s(_vm.$tc("validation.required")))
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.$v.registration.email.email
                  ? _c("span", { staticClass: "text-danger" }, [
                      _vm._v(_vm._s(_vm.$tc("validation.required")))
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.errors.email != ""
                  ? _c("span", { staticClass: "text-danger" }, [
                      _vm._v(_vm._s(_vm.errors.email))
                    ])
                  : _vm._e()
              ])
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("label", { staticClass: "form-label" }, [
            _vm._v(_vm._s(_vm.$t("register.password")))
          ]),
          _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
          _vm._v(" "),
          _c("base-input", {
            attrs: {
              invalid: _vm.$v.registration.password.$error,
              type: "password",
              name: "password"
            },
            on: {
              input: function($event) {
                return _vm.$v.registration.password.$touch()
              }
            },
            model: {
              value: _vm.registration.password,
              callback: function($$v) {
                _vm.$set(
                  _vm.registration,
                  "password",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "registration.password"
            }
          }),
          _vm._v(" "),
          _vm.$v.registration.password.$error
            ? _c("div", [
                !_vm.$v.registration.password.required
                  ? _c("span", { staticClass: "text-danger" }, [
                      _vm._v(_vm._s(_vm.$tc("validation.required")))
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.$v.registration.password.minLength
                  ? _c("span", { staticClass: "text-danger" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$tc(
                              "validation.password_min_length",
                              _vm.$v.registration.password.$params.minLength
                                .min,
                              {
                                count:
                                  _vm.$v.registration.password.$params.minLength
                                    .min
                              }
                            )
                          ) +
                          " "
                      )
                    ])
                  : _vm._e()
              ])
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "base-button",
        {
          staticClass: "pull-right mt-4",
          attrs: {
            loading: _vm.loading,
            icon: "save",
            color: "theme",
            type: "submit"
          }
        },
        [_vm._v("\n    " + _vm._s(_vm.$t("login.register")) + "\n  ")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "other-actions row" }, [
        _c("div", { staticClass: "col-sm-12 text-sm-left" }, [
          _c("p", [
            _vm._v(" " + _vm._s(_vm.$t("register.already_account")) + " "),
            _c("a", { attrs: { href: "/login" } }, [
              _vm._v(" " + _vm._s(_vm.$t("login.login")))
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }