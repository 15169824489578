import * as types from './mutation-types'

export const fetchSuppliers = ({ commit, dispatch, state }, params) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/api/suppliers`, { params }).then((response) => {
            commit(types.BOOTSTRAP_SUPPLIERS, response.data.suppliers.data)
            commit(types.SET_TOTAL_SUPPLIERS, response.data.suppliers.total)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}
export const inviteSupplier = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('/api/suppliers/invite', data).then((response) => {
          resolve(response)
        }).catch((err) => {
          reject(err)
        })
      })
}

export const setSelectAllState = ({ commit, dispatch, state }, data) => {
    commit(types.SET_SELECT_ALL_STATE, data)
}

export const selectAllSuppliers = ({ commit, dispatch, state }) => {
    if (state.selectedSuppliers.length === state.suppliers.length) {
        commit(types.SET_SELECTED_SUPPLIERS, [])
        commit(types.SET_SELECT_ALL_STATE, false)
    } else {
        let allSupplierIds = state.suppliers.map(cust => cust.id)
        commit(types.SET_SELECTED_SUPPLIERS, allSupplierIds)
        commit(types.SET_SELECT_ALL_STATE, true)
    }
}

export const selectSupplier = ({ commit, dispatch, state }, data) => {
    commit(types.SET_SELECTED_SUPPLIERS, data)
    if (state.selectedSuppliers.length === state.suppliers.length) {
        commit(types.SET_SELECT_ALL_STATE, true)
    } else {
        commit(types.SET_SELECT_ALL_STATE, false)
    }
}

export const resetSelectedSupplier = ({ commit, dispatch, state }, data) => {
    commit(types.RESET_SELECTED_SUPPLIER)
}