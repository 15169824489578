<template>
  <div class="customer-create main-content">
    <div class="page-header">
      <h3 class="page-title">{{ $t("suppliers.title") }}</h3>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link slot="item-title" to="dashboard">
            {{ $t("general.home") }}
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link slot="item-title" to="#">
            {{ $tc("suppliers.supplier", 2) }}
          </router-link>
        </li>
      </ol>
      <div class="page-actions row">
        <div class="col-xs-2 mr-4">
          <base-button
            v-show="totalSuppliers || filtersApplied"
            :outline="true"
            :icon="filterIcon"
            size="large"
            color="theme"
            right-icon
            @click="toggleFilter"
          >
            {{ $t("general.filter") }}
          </base-button>
        </div>
        <router-link slot="item-title" class="col-xs-2" to="suppliers/invite">
          <base-button
            size="large"
            icon="share"
            color="theme">
            {{ $t('suppliers.invite_supplier') }}
          </base-button>
        </router-link>
      </div>
    </div>

    <transition name="fade">
      <div v-show="showFilters" class="filter-section">
        <div class="row">
          <div class="col-sm-4">
            <label class="form-label">{{ $t("suppliers.display_name") }}</label>
            <base-input
              v-model="filters.display_name"
              type="text"
              name="name"
              autocomplete="off"
            />
          </div>
          <div class="col-sm-4">
            <label class="form-label">{{ $t("suppliers.email") }}</label>
            <base-input
              v-model="filters.email"
              type="text"
              name="address_name"
              autocomplete="off"
            />
          </div>
          <div class="col-sm-4">
            <label class="form-label">{{ $t("suppliers.phone") }}</label>
            <base-input
              v-model="filters.phone"
              type="text"
              name="phone"
              autocomplete="off"
            />
          </div>
          <label class="clear-filter" @click="clearFilter">{{
            $t("general.clear_all")
          }}</label>
        </div>
      </div>
    </transition>

    <div
      v-cloak
      v-show="showEmptyScreen"
      class="col-xs-1 no-data-info"
      align="center"
    >
      <astronaut-icon class="mt-5 mb-4" />
      <div class="row" align="center">
        <label class="col title">{{ $t("suppliers.no_suppliers") }}</label>
      </div>
      <div class="row">
        <label class="description col mt-1" align="center">{{
          $t("suppliers.list_of_suppliers")
        }}</label>
      </div>
    </div>

    <div v-show="!showEmptyScreen" class="table-container">
      <div class="table-actions mt-5">
        <p class="table-stats">
          {{ $t("general.showing") }}: <b>{{ suppliers.length }}</b>
          {{ $t("general.of") }} <b>{{ totalSuppliers }}</b>
        </p>

        <transition name="fade">
          <v-dropdown v-if="selectedSuppliers.length" :show-arrow="false">
            <span
              slot="activator"
              href="#"
              class="table-actions-button dropdown-toggle"
            >
              {{ $t("general.actions") }}
            </span>
          </v-dropdown>
        </transition>
      </div>

      <div class="custom-control custom-checkbox">
        <input
          id="select-all"
          v-model="selectAllFieldStatus"
          type="checkbox"
          class="custom-control-input"
          @change="selectAllSuppliers"
        />
        <label for="select-all" class="custom-control-label selectall">
          <span class="select-all-label">{{ $t("general.select_all") }} </span>
        </label>
      </div>

      <table-component
        ref="table"
        :show-filter="false"
        :data="fetchData"
        table-class="table"
      >
        <table-column
          :sortable="false"
          :filterable="false"
          cell-class="no-click"
        >
          <template slot-scope="row">
            <div class="custom-control custom-checkbox">
              <input
                :id="row.id"
                v-model="selectField"
                :value="row.id"
                type="checkbox"
                class="custom-control-input"
              />
              <label :for="row.id" class="custom-control-label" />
            </div>
          </template>
        </table-column>
        <table-column :label="$t('suppliers.display_name')" show="name" />
        <table-column :label="$t('suppliers.email')" show="email" />
        <table-column :label="$t('suppliers.phone')" show="phone" />
        <table-column
          :sortable="false"
          :filterable="false"
          cell-class="action-dropdown"
        >
          <template slot-scope="row">
            <span> {{ $t("suppliers.action") }} </span>
            <v-dropdown>
              <a slot="activator" href="#">
                <dot-icon />
              </a>
              <v-dropdown-item>
                <router-link
                  :to="{ path: `payables`, query: { user: row } }"
                  class="dropdown-item"
                >
                  <font-awesome-icon
                    :icon="['fas', 'eye']"
                    class="dropdown-item-icon"
                  />
                  {{ $t("general.view") }}
                </router-link>
              </v-dropdown-item>
            </v-dropdown>
          </template>
        </table-column>
      </table-component>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { SweetModal, SweetModalTab } from "sweet-modal-vue";
import DotIcon from "../../components/icon/DotIcon";
import AstronautIcon from "../../components/icon/AstronautIcon";
import BaseButton from "../../../js/components/base/BaseButton";
import { request } from "http";

export default {
  components: {
    DotIcon,
    AstronautIcon,
    SweetModal,
    SweetModalTab,
    BaseButton,
  },
  data() {
    return {
      showFilters: false,
      filtersApplied: false,
      isRequestOngoing: true,
      filters: {
        display_name: "",
        email: "",
        phone: "",
      },
    };
  },
  computed: {
    showEmptyScreen() {
      return (
        !this.totalSuppliers && !this.isRequestOngoing && !this.filtersApplied
      );
    },
    filterIcon() {
      return this.showFilters ? "times" : "filter";
    },
    ...mapGetters("supplier", [
      "suppliers",
      "selectedSuppliers",
      "totalSuppliers",
      "selectAllField",
    ]),
    selectField: {
      get: function () {
        return this.selectedSuppliers;
      },
      set: function (val) {
        this.selectSupplier(val);
      },
    },
    selectAllFieldStatus: {
      get: function () {
        return this.selectAllField;
      },
      set: function (val) {
        this.setSelectAllState(val);
      },
    },
  },
  watch: {
    filters: {
      handler: "setFilters",
      deep: true,
    },
    isRequestOngoing(newVal) {
      if (newVal) {
        if (this.selectAllField || this.selectedSuppliers.length > 0) {
          this.setSelectAllState(false);
          this.selectedSupplier([]);
        }
      }
    },
  },
  destroyed() {
    if (this.selectAllField) {
      this.selectAllSuppliers();
    }
  },
  methods: {
    ...mapActions("supplier", [
      "fetchSuppliers",
      "selectAllSuppliers",
      "selectSupplier",
      "setSelectAllState",
    ]),
    refreshTable() {
      this.$refs.table.refresh();
    },
    async fetchData({ page, filter, sort }) {
      let data = {
        display_name: this.filters.display_name,
        email: this.filters.email,
        phone: this.filters.phone,
        orderByField: sort.fieldName || "created_at",
        orderBy: sort.order || "desc",
        page,
      };

      this.isRequestOngoing = true;
      let response = await this.fetchSuppliers(data);
      this.isRequestOngoing = false;

      return {
        data: response.data.suppliers.data,
        pagination: {
          totalPages: response.data.suppliers.last_page,
          currentPage: page,
        },
      };
    },
    setFilters() {
      this.filtersApplied = true;
      this.refreshTable();
    },
    clearFilter() {
      this.filters = {
        display_name: "",
        email: "",
        phone: "",
      };

      this.$nextTick(() => {
        this.filtersApplied = false;
      });
    },
    toggleFilter() {
      if (this.showFilters && this.filtersApplied) {
        this.clearFilter();
        this.refreshTable();
      }

      this.showFilters = !this.showFilters;
    },
  },
};
</script>
