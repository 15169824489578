<template>
  <div class="item-selector">
    <base-select
      ref="baseSelect"
      v-model="supplierSelect"
      :options="suppliers"
      :show-labels="false"
      :preserve-search="false"
      :placeholder="$t('customers.type_or_click')"
      label="name"
      class="multi-select-item"
      @close="checkSuppliers"
      @value="onTextChange"
      @select="(val) => $emit('select', val)"
      @remove="deselectSupplier"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: ['supplier'],
  data () {
    return {
      supplierSelect: this.supplier,
      loading: false
    }
  },
  watch: {
    supplier:function(newValue,oldValue) {
      console.log('Prop changed: ', newValue, ' | was: ', oldValue)
      this.supplierSelect=newValue
    }
  },
  computed: {
    ...mapGetters('supplier', [
      'suppliers'
    ])
  },
  methods: {
    ...mapActions('supplier', [
      'fetchSuppliers'
    ]),
    async searchSuppliers (search) {
      this.loading = true

      await this.fetchSuppliers({search})

      this.loading = false
    },
    onTextChange (val) {
      this.searchSuppliers(val)
    },
    checkSuppliers (val) {
      if (!this.suppliers.length) {
        this.fetchSuppliers()
      }
    },
    deselectSupplier () {
      this.supplierSelect = null
      this.$emit('deselect')
    }
  }
}
</script>
