var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "payment-modes-modal" }, [
    _c(
      "form",
      {
        attrs: { action: "" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.submitPaymentMode($event)
          }
        }
      },
      [
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "form-group row" }, [
            _c(
              "label",
              { staticClass: "col-sm-4 col-form-label input-label" },
              [
                _vm._v(
                  _vm._s(_vm.$t("settings.customization.payments.mode_name")) +
                    " "
                ),
                _c("span", { staticClass: "required" }, [_vm._v(" *")])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-sm-7" },
              [
                _c("base-input", {
                  ref: "name",
                  attrs: { invalid: _vm.$v.formData.name.$error, type: "text" },
                  on: {
                    input: function($event) {
                      return _vm.$v.formData.name.$touch()
                    }
                  },
                  model: {
                    value: _vm.formData.name,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "name", $$v)
                    },
                    expression: "formData.name"
                  }
                }),
                _vm._v(" "),
                _vm.$v.formData.name.$error
                  ? _c("div", [
                      !_vm.$v.formData.name.required
                        ? _c(
                            "span",
                            { staticClass: "form-group__message text-danger" },
                            [_vm._v(_vm._s(_vm.$tc("validation.required")))]
                          )
                        : _vm._e()
                    ])
                  : _vm._e()
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card-footer" },
          [
            _c(
              "base-button",
              {
                staticClass: "mr-3",
                attrs: { outline: true, color: "theme", type: "button" },
                on: { click: _vm.closePaymentModeModal }
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("general.cancel")) + "\n      "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "base-button",
              {
                attrs: {
                  loading: _vm.isLoading,
                  color: "theme",
                  icon: "save",
                  type: "submit"
                }
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      !_vm.isEdit
                        ? _vm.$t("general.save")
                        : _vm.$t("general.update")
                    ) +
                    "\n      "
                )
              ]
            )
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }