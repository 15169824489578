var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "base-date-input",
      class: [_vm.wrapperClass, _vm.isRtl ? "rtl" : ""]
    },
    [
      _c(
        "date-input",
        {
          class: { "required-date": _vm.invalid },
          attrs: {
            "selected-date": _vm.selectedDate,
            "reset-typed-date": _vm.resetTypedDate,
            format: _vm.customFormatter,
            translation: _vm.translation,
            inline: _vm.inline,
            id: _vm.id,
            name: _vm.name,
            "ref-name": _vm.refName,
            "open-date": _vm.openDate,
            placeholder: _vm.placeholder,
            "input-class": _vm.inputClass,
            typeable: _vm.typeable,
            "clear-button": _vm.clearButton,
            "clear-button-icon": _vm.clearButtonIcon,
            "calendar-button": _vm.calendarButton,
            "calendar-button-icon": _vm.calendarButtonIcon,
            "calendar-button-icon-content": _vm.calendarButtonIconContent,
            disabled: _vm.disabled,
            required: _vm.required,
            "bootstrap-styling": _vm.bootstrapStyling,
            "use-utc": _vm.useUtc
          },
          on: {
            showCalendar: _vm.showCalendar,
            closeCalendar: _vm.close,
            typedDate: _vm.setTypedDate,
            clearDate: _vm.clearDate
          }
        },
        [_vm._t("afterDateInput", null, { slot: "afterDateInput" })],
        2
      ),
      _vm._v(" "),
      _vm.allowedToShowView("day")
        ? _c(
            "picker-day",
            {
              attrs: {
                "page-date": _vm.pageDate,
                "selected-date": _vm.selectedDate,
                "show-day-view": _vm.showDayView,
                "full-month-name": _vm.fullMonthName,
                "allowed-to-show-view": _vm.allowedToShowView,
                "disabled-dates": _vm.disabledDates,
                highlighted: _vm.highlighted,
                "calendar-class": _vm.calendarClass,
                "calendar-style": _vm.calendarStyle,
                translation: _vm.translation,
                "page-timestamp": _vm.pageTimestamp,
                "is-rtl": _vm.isRtl,
                "monday-first": _vm.mondayFirst,
                "day-cell-content": _vm.dayCellContent,
                "use-utc": _vm.useUtc
              },
              on: {
                changedMonth: _vm.handleChangedMonthFromDayPicker,
                selectDate: _vm.selectDate,
                showMonthCalendar: _vm.showMonthCalendar,
                selectedDisabled: _vm.selectDisabledDate
              }
            },
            [
              _vm._t("beforeCalendarHeader", null, {
                slot: "beforeCalendarHeader"
              })
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.allowedToShowView("month")
        ? _c(
            "picker-month",
            {
              attrs: {
                "page-date": _vm.pageDate,
                "selected-date": _vm.selectedDate,
                "show-month-view": _vm.showMonthView,
                "allowed-to-show-view": _vm.allowedToShowView,
                "disabled-dates": _vm.disabledDates,
                "calendar-class": _vm.calendarClass,
                "calendar-style": _vm.calendarStyle,
                translation: _vm.translation,
                "is-rtl": _vm.isRtl,
                "use-utc": _vm.useUtc
              },
              on: {
                selectMonth: _vm.selectMonth,
                showYearCalendar: _vm.showYearCalendar,
                changedYear: _vm.setPageDate
              }
            },
            [
              _vm._t("beforeCalendarHeader", null, {
                slot: "beforeCalendarHeader"
              })
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.allowedToShowView("year")
        ? _c(
            "picker-year",
            {
              attrs: {
                "page-date": _vm.pageDate,
                "selected-date": _vm.selectedDate,
                "show-year-view": _vm.showYearView,
                "allowed-to-show-view": _vm.allowedToShowView,
                "disabled-dates": _vm.disabledDates,
                "calendar-class": _vm.calendarClass,
                "calendar-style": _vm.calendarStyle,
                translation: _vm.translation,
                "is-rtl": _vm.isRtl,
                "use-utc": _vm.useUtc
              },
              on: { selectYear: _vm.selectYear, changedDecade: _vm.setPageDate }
            },
            [
              _vm._t("beforeCalendarHeader", null, {
                slot: "beforeCalendarHeader"
              })
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }