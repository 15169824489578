var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "39",
        height: "39",
        viewBox: "0 0 39 39",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M19.4104 39.0002C30.0264 39.0002 38.6324 30.3942 38.6324 19.7782C38.6324 9.16215 30.0264 0.556152 19.4104 0.556152C8.79435 0.556152 0.188354 9.16215 0.188354 19.7782C0.188354 30.3942 8.79435 39.0002 19.4104 39.0002Z",
          fill: "#55ACEE"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M31.1843 14.3704C30.3605 14.7357 29.4744 14.9827 28.5452 15.0931C29.494 14.5246 30.2221 13.6251 30.5658 12.5516C29.678 13.0783 28.6942 13.4603 27.6481 13.6663C26.81 12.7737 25.6159 12.2158 24.2936 12.2158C21.7566 12.2158 19.699 14.2734 19.699 16.8104C19.699 17.1706 19.7397 17.5211 19.8185 17.8576C16 17.666 12.6143 15.837 10.348 13.0563C9.95261 13.7348 9.72577 14.5246 9.72577 15.3665C9.72577 16.9602 10.5375 18.3671 11.7697 19.1908C11.0169 19.1672 10.3079 18.9606 9.68876 18.6155C9.68842 18.635 9.68842 18.6546 9.68842 18.6738C9.68842 20.9 11.2728 22.7568 13.3743 23.1786C12.9892 23.2841 12.5825 23.34 12.1641 23.34C11.8673 23.34 11.5799 23.3115 11.2996 23.2581C11.8841 25.083 13.5806 26.4115 15.5916 26.4489C14.0188 27.6814 12.038 28.4157 9.88476 28.4157C9.5147 28.4157 9.14806 28.3941 8.78931 28.3512C10.8216 29.6554 13.2373 30.4157 15.8318 30.4157C24.2829 30.4157 28.9046 23.4147 28.9046 17.3426C28.9046 17.1435 28.9002 16.9451 28.8913 16.7484C29.7897 16.1008 30.5685 15.2918 31.1843 14.3704Z",
          fill: "#F1F2F2"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }