var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "verify-email-area border p-3" }, [
    _c(
      "svg",
      {
        staticClass: "svg-inline--fa fa-check-circle text-success",
        attrs: {
          "aria-hidden": "true",
          focusable: "false",
          "data-prefix": "fas",
          "data-icon": "check-circle",
          role: "img",
          xmlns: "http://www.w3.org/2000/svg",
          viewBox: "0 0 512 512"
        }
      },
      [
        _c("path", {
          attrs: {
            fill: "currentColor",
            d:
              "M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
          }
        })
      ]
    ),
    _vm._v(" "),
    _c("h5", [_vm._v("Great, You are successfully registered.")]),
    _vm._v(" "),
    _c("h5", { staticClass: "sent-email-text" }, [
      _vm._v(
        "We have sent you an email to verify your email address, visit your inbox"
      )
    ]),
    _vm._v(" "),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h6", [
      _vm._v("If you have already verified click "),
      _c("a", { attrs: { href: "/login" } }, [_vm._v(" Login")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }