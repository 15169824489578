<template>
  <form action="" @submit.prevent="register()">
    <div class="form-group">
      <label class="form-label">{{ $t('register.company_name') }}</label><span class="text-danger"> *</span>
      <base-input
              :invalid="$v.registration.company.$error || errors.company != ''"
              v-model.trim="registration.company"
              type="text"
              name="name"
              @input="$v.registration.company.$touch()"
      />
      <div v-if="$v.registration.company.$error">
        <span v-if="!$v.registration.company.required" class="text-danger">{{ $tc('validation.required') }}</span>
      </div>
    </div>
    <div class="form-group">
      <label class="form-label">{{ $t('register.name') }}</label><span class="text-danger"> *</span>
      <base-input
              :invalid="$v.registration.name.$error"
              v-model.trim="registration.name"
              type="text"
              name="name"
              @input="$v.registration.name.$touch()"
      />
      <div v-if="$v.registration.name.$error">
        <span v-if="!$v.registration.name.required" class="text-danger">{{ $tc('validation.required') }}</span>
        <span v-if="!$v.registration.name.minLength" class="text-danger"> {{ $tc('validation.name_min_length', $v.registration.name.$params.minLength.min, { count: $v.registration.name.$params.minLength.min }) }} </span>
      </div>
    </div>
    <div class="form-group">
      <label class="form-label">{{ $t('register.email') }}</label><span class="text-danger"> *</span>
      <base-input
              :invalid="$v.registration.email.$error || errors.email != ''"
              v-model.trim="registration.email"
              type="text"
              name="email"
              @input="$v.registration.email.$touch()"
      />
      <div v-if="$v.registration.email.$error || errors.email != ''">
        <span v-if="!$v.registration.email.isUnique" class="text-danger">{{ $tc('validation.email_already_taken') }}</span>
        <span v-if="!$v.registration.email.required" class="text-danger">{{ $tc('validation.required') }}</span>
        <span v-if="!$v.registration.email.email" class="text-danger">{{ $tc('validation.required') }}</span>
        <span v-if="errors.email != ''" class="text-danger">{{ errors.email }}</span>
      </div>
    </div>
    <div class="form-group">
      <label class="form-label">{{ $t('register.password') }}</label><span class="text-danger"> *</span>
      <base-input
              :invalid="$v.registration.password.$error"
              v-model.trim="registration.password"
              type="password"
              name="password"
              @input="$v.registration.password.$touch()"
      />
      <div v-if="$v.registration.password.$error">
        <span v-if="!$v.registration.password.required" class="text-danger">{{ $tc('validation.required') }}</span>
        <span v-if="!$v.registration.password.minLength" class="text-danger"> {{ $tc('validation.password_min_length', $v.registration.password.$params.minLength.min, {count: $v.registration.password.$params.minLength.min}) }} </span>
      </div>
    </div>

    <base-button :loading="loading" class="pull-right mt-4" icon="save" color="theme" type="submit">
      {{ $t('login.register') }}
    </base-button>

    <div class="other-actions row">
      <div class="col-sm-12 text-sm-left">
        <p> {{ $t('register.already_account') }} <a href="/login"> {{ $t('login.login') }}</a></p>
      </div>
    </div>

  </form>
</template>
<script>

  import Ls from '@/services/ls'
  import { mapActions } from 'vuex'

  import { validationMixin } from 'vuelidate'

  const { required, requiredIf, sameAs, minLength,maxLength, email } = require('vuelidate/lib/validators')

  export default {
    components: {

    },
    mixins: [validationMixin],
    data () {
      return {
        reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
        registration: {
          name: null,
          email: null,
          password:null,
          company: null
        },
        errors : {
          company : '',
          email:''
        },
        loading: false
      }
    },
    validations: {
      registration: {
        name: {
          required,
          minLength: minLength(3)
        },
        email: {
          required,
          email,
          isUnique(value) {
            // standalone validator ideally should not assume a field is required
            if (value === '') return true

            // simulate async call, fail for all logins with even length
            return new Promise((resolve, reject) => {
              setTimeout(() => {
                resolve(this.isRegistered(value))
              }, 350 + Math.random() * 300)
            })
          }
        },
        password: {
          required,
          minLength: minLength(8)
        },
        company: {
          required
        }
      }
    },
    watch: {

    },
    mounted () {

    },
    methods: {
      ...mapActions('auth', [
        'loginOnBoardingUser'
      ]),
      isEmailValid: function(email) {
        return (email === '')? '' : (this.reg.test(email)) ? true : false;
      },
      async isRegistered(value){

        if(this.isEmailValid(value)==false) return true;

        let response = await window.axios.post('/api/is-registered', {email:value})

        if (response.data)
        {
          return !response.data
        }

        return true;//default

      },
      async register () {

        this.$v.registration.$touch()

        if (this.$v.registration.$invalid) {
          return true
        }

        this.loading = true

        let response = await window.axios.post('/api/registration/create', this.registration)

        if (response.data) {

          Ls.set('selectedCompany',response.data.cid)

          this.loading = false

          this.$router.push('verify-email')

        }

      }
    }
  }
</script>