var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-content item-create" }, [
    _c("div", { staticClass: "page-header" }, [
      _c("h3", { staticClass: "page-title" }, [
        _vm._v(
          _vm._s(
            _vm.isEdit ? _vm.$t("items.edit_item") : _vm.$t("items.new_item")
          )
        )
      ]),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              {
                attrs: { slot: "item-title", to: "/admin/dashboard" },
                slot: "item-title"
              },
              [_vm._v(_vm._s(_vm.$t("general.home")))]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              {
                attrs: { slot: "item-title", to: "/admin/items" },
                slot: "item-title"
              },
              [_vm._v(_vm._s(_vm.$tc("items.item", 2)))]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("a", { attrs: { href: "#" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.isEdit
                    ? _vm.$t("items.edit_item")
                    : _vm.$t("items.new_item")
                )
            )
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12" }, [
        _c("div", { staticClass: "card" }, [
          _c(
            "form",
            {
              attrs: { action: "" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.submitItem($event)
                }
              }
            },
            [
              _c("div", { staticClass: "card-body" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-4" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { staticClass: "control-label" }, [
                          _vm._v(_vm._s(_vm.$t("items.name")))
                        ]),
                        _c("span", { staticClass: "text-danger" }, [
                          _vm._v(" *")
                        ]),
                        _vm._v(" "),
                        _c("base-input", {
                          attrs: {
                            invalid: _vm.$v.formData.name.$error,
                            focus: "",
                            type: "text",
                            name: "name"
                          },
                          on: {
                            input: function($event) {
                              return _vm.$v.formData.name.$touch()
                            }
                          },
                          model: {
                            value: _vm.formData.name,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.formData,
                                "name",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "formData.name"
                          }
                        }),
                        _vm._v(" "),
                        _vm.$v.formData.name.$error
                          ? _c("div", [
                              !_vm.$v.formData.name.required
                                ? _c("span", { staticClass: "text-danger" }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("validation.required")) +
                                        " "
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.$v.formData.name.minLength
                                ? _c("span", { staticClass: "text-danger" }, [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(
                                          _vm.$tc(
                                            "validation.name_min_length",
                                            _vm.$v.formData.name.$params
                                              .minLength.min,
                                            {
                                              count:
                                                _vm.$v.formData.name.$params
                                                  .minLength.min
                                            }
                                          )
                                        ) +
                                        "\n                    "
                                    )
                                  ])
                                : _vm._e()
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-4" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", [_vm._v(_vm._s(_vm.$t("items.price")))]),
                      _c("span", { staticClass: "text-danger" }, [
                        _vm._v(" *")
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "base-input" },
                        [
                          _c(
                            "money",
                            _vm._b(
                              {
                                staticClass: "input-field",
                                class: {
                                  invalid: _vm.$v.formData.price.$error
                                },
                                model: {
                                  value: _vm.price,
                                  callback: function($$v) {
                                    _vm.price = $$v
                                  },
                                  expression: "price"
                                }
                              },
                              "money",
                              _vm.defaultCurrencyForInput,
                              false
                            )
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.$v.formData.price.$error
                        ? _c("div", [
                            !_vm.$v.formData.price.required
                              ? _c("span", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("validation.required")) + " "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.$v.formData.price.maxLength
                              ? _c("span", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("validation.price_maxlength"))
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.$v.formData.price.minValue
                              ? _c("span", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("validation.price_minvalue"))
                                  )
                                ])
                              : _vm._e()
                          ])
                        : _vm._e()
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-4" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", [_vm._v(_vm._s(_vm.$t("items.unit")))]),
                        _vm._v(" "),
                        _c(
                          "base-select",
                          {
                            attrs: {
                              options: _vm.itemUnits,
                              searchable: true,
                              "show-labels": false,
                              placeholder: _vm.$t("items.select_a_unit"),
                              label: "name"
                            },
                            model: {
                              value: _vm.formData.unit,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "unit", $$v)
                              },
                              expression: "formData.unit"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                attrs: { slot: "afterList" },
                                slot: "afterList"
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "list-add-button",
                                    attrs: { type: "button" },
                                    on: { click: _vm.addItemUnit }
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      staticClass: "icon",
                                      attrs: { icon: "cart-plus" }
                                    }),
                                    _vm._v(" "),
                                    _c("label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "settings.customization.items.add_item_unit"
                                          )
                                        )
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ]
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-12" }, [
                    _vm.isTaxPerItem
                      ? _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", [
                              _vm._v(_vm._s(_vm.$t("items.taxes")))
                            ]),
                            _vm._v(" "),
                            _c("base-select", {
                              attrs: {
                                options: _vm.getTaxTypes,
                                searchable: true,
                                "show-labels": false,
                                "allow-empty": true,
                                multiple: true,
                                "track-by": "tax_type_id",
                                label: "tax_name"
                              },
                              model: {
                                value: _vm.formData.taxes,
                                callback: function($$v) {
                                  _vm.$set(_vm.formData, "taxes", $$v)
                                },
                                expression: "formData.taxes"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "description" } }, [
                          _vm._v(_vm._s(_vm.$t("items.description")))
                        ]),
                        _vm._v(" "),
                        _c("base-text-area", {
                          attrs: { rows: "4", name: "description" },
                          on: {
                            input: function($event) {
                              return _vm.$v.formData.description.$touch()
                            }
                          },
                          model: {
                            value: _vm.formData.description,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "description", $$v)
                            },
                            expression: "formData.description"
                          }
                        }),
                        _vm._v(" "),
                        _vm.$v.formData.description.$error
                          ? _c("div", [
                              !_vm.$v.formData.description.maxLength
                                ? _c("span", { staticClass: "text-danger" }, [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(
                                          _vm.$t(
                                            "validation.description_maxlength"
                                          )
                                        ) +
                                        "\n                    "
                                    )
                                  ])
                                : _vm._e()
                            ])
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "d-flex justify-content-center" },
                      [
                        _c(
                          "base-button",
                          {
                            staticClass: "collapse-button mb-3",
                            attrs: {
                              loading: _vm.isLoading,
                              disabled: _vm.isLoading,
                              icon: "save",
                              color: "theme",
                              type: "submit"
                            }
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm.isEdit
                                    ? _vm.$t("items.update_item")
                                    : _vm.$t("items.save_item")
                                ) +
                                "\n                  "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ])
              ])
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }