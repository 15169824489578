<template>
  <div v-if="payable" class="main-content invoice-view-page">
    <div class="page-header">
      <h3 class="page-title"> {{ payable.invoice_number }}</h3>
      <div class="page-actions row">
      </div>
    </div>
    <div class="invoice-sidebar">
      <div class="side-header">
        <base-input
          v-model="searchData.searchText"
          :placeholder="$t('general.search')"
          input-class="inv-search"
          icon="search"
          type="text"
          align-icon="right"
          @input="onSearch"
        />
        <div
          class="btn-group ml-3"
          role="group"
          aria-label="First group"
        >
          <v-dropdown :close-on-select="false" align="left" class="filter-container">
            <a slot="activator" href="#">
              <base-button class="inv-button inv-filter-fields-btn" color="default" size="medium">
                <font-awesome-icon icon="filter" />
              </base-button>
            </a>
            <div class="filter-title">
              {{ $t('general.sort_by') }}
            </div>
            <div class="filter-items">
              <input
                id="filter_invoice_date"
                v-model="searchData.orderByField"
                type="radio"
                name="filter"
                class="inv-radio"
                value="invoice_date"
                @change="onSearch"
              >
              <label class="inv-label" for="filter_invoice_date">{{ $t('invoices.invoice_date') }}</label>
            </div>
            <div class="filter-items">
              <input
                id="filter_due_date"
                v-model="searchData.orderByField"
                type="radio"
                name="filter"
                class="inv-radio"
                value="due_date"
                @change="onSearch"
              >
              <label class="inv-label" for="filter_due_date">{{ $t('invoices.due_date') }}</label>
            </div>
            <div class="filter-items">
              <input
                id="filter_invoice_number"
                v-model="searchData.orderByField"
                type="radio"
                name="filter"
                class="inv-radio"
                value="invoice_number"
                @change="onSearch"
              >
              <label class="inv-label" for="filter_invoice_number">{{ $t('invoices.invoice_number') }}</label>
            </div>
          </v-dropdown>
          <base-button v-tooltip.top-center="{ content: getOrderName }" class="inv-button inv-filter-sorting-btn" color="default" size="medium" @click="sortData">
            <font-awesome-icon v-if="getOrderBy" icon="sort-amount-up" />
            <font-awesome-icon v-else icon="sort-amount-down" />
          </base-button>
        </div>
      </div>
      <base-loader v-if="isSearching" />
      <div v-else class="side-content">
        <router-link
          v-for="(payable,index) in payables"
          :to="`/admin/payables/${payable.unique_hash}/view`"
          :key="index"
          class="side-invoice"
        >
          <div class="left">
            <div class="inv-name">{{ payable.company.user.name }}</div>
            <div class="inv-number">{{ payable.invoice_number }}</div>
            <div :class="'inv-status-'+payable.status.toLowerCase()" class="inv-status">{{ payable.status }}</div>
          </div>
          <div class="right">
            <div class="inv-amount" v-html="$utils.formatMoney(payable.due_amount, payable.user.currency)" />
            <div class="inv-date">{{ payable.formattedInvoiceDate }}</div>
          </div>
        </router-link>
        <p v-if="!payables.length" class="no-result">
          {{ $t('invoices.no_matching_invoices') }}
        </p>
      </div>
    </div>
    <div class="invoice-view-page-container" >
      <iframe :src="`${shareableLink}`" class="frame-style"/>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
const _ = require('lodash')
export default {
  data () {
    return {
      id: null,
      count: null,
      payables: [],
      payable: null,
      currency: null,
      searchData: {
        orderBy: null,
        orderByField: null,
        searchText: null
      },
      isRequestOnGoing: false,
      isSearching: false,
      isSendingEmail: false,
      isMarkingAsSent: false
    }
  },
  computed: {
    getOrderBy () {
      if (this.searchData.orderBy === 'asc' || this.searchData.orderBy == null) {
        return true
      }
      return false
    },
    getOrderName () {
      if (this.getOrderBy) {
        return this.$t('general.ascending')
      }
      return this.$t('general.descending')
    },
    shareableLink () {
      return `/invoices/pdf/${this.payable.unique_hash}`
    }
  },
  watch: {
    $route (to, from) {
      this.loadPayble()
    }
  },
  created () {
    this.loadPaybles()
    this.loadPayble()
    this.onSearch = _.debounce(this.onSearch, 500)
  },
  methods: {
    ...mapActions('payable', [
      'fetchPayables',
      'selectPayable',
      'searchPayable',
      'resetSelectedPayables',
      'selectAllPayables',
      'setSelectAllState',
      'fetchViewPayable'
    ]),
    async loadPaybles () {
      let response = await this.fetchPayables()
      if (response.data) {
        this.payables = response.data.payables.data
      }
    },
    async loadPayble () {
      let response = await this.fetchViewPayable(this.$route.params.hash)

      if (response.data) {
        this.payable = response.data.payable
      }
    },
    async onSearch () {
      let data = ''
      if (this.searchData.searchText !== '' && this.searchData.searchText !== null && this.searchData.searchText !== undefined) {
        data += `search=${this.searchData.searchText}&`
      }

      if (this.searchData.orderBy !== null && this.searchData.orderBy !== undefined) {
        data += `orderBy=${this.searchData.orderBy}&`
      }

      if (this.searchData.orderByField !== null && this.searchData.orderByField !== undefined) {
        data += `orderByField=${this.searchData.orderByField}`
      }
      this.isSearching = true
      let response = await this.searchPayable(data)
      this.isSearching = false
      if (response.data) {
        this.payables = response.data.payables.data
      }
    },
    sortData () {
      if (this.searchData.orderBy === 'asc') {
        this.searchData.orderBy = 'desc'
        this.onSearch()
        return true
      }
      this.searchData.orderBy = 'asc'
      this.onSearch()
      return true
    },
  }
}
</script>
