var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "item-selector" },
    [
      _c("base-select", {
        ref: "baseSelect",
        staticClass: "multi-select-item",
        attrs: {
          options: _vm.suppliers,
          "show-labels": false,
          "preserve-search": false,
          placeholder: _vm.$t("customers.type_or_click"),
          label: "name"
        },
        on: {
          close: _vm.checkSuppliers,
          value: _vm.onTextChange,
          select: function(val) {
            return _vm.$emit("select", val)
          },
          remove: _vm.deselectSupplier
        },
        model: {
          value: _vm.supplierSelect,
          callback: function($$v) {
            _vm.supplierSelect = $$v
          },
          expression: "supplierSelect"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }