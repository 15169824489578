import * as types from './mutation-types'

export default {
    [types.BOOTSTRAP_SUPPLIERS](state, suppliers) {
        state.suppliers = suppliers
    },

    [types.SET_TOTAL_SUPPLIERS](state, totalSuppliers) {
        state.totalSuppliers = totalSuppliers
    },

    [types.SET_SELECTED_SUPPLIERS](state, data) {
        state.selectedSuppliers = data
    },

    [types.RESET_SELECTED_SUPPLIER](state, data) {
        state.selectedSupplier = null
    },

    [types.SET_SELECT_ALL_STATE](state, data) {
        state.selectAllField = data
    }

}
