var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "tr",
    { on: { click: _vm.onClick } },
    _vm._l(_vm.visibleColumns, function(column) {
      return _c("table-cell", {
        key: column.id,
        attrs: {
          row: _vm.row,
          column: column,
          "responsive-label": column.label
        }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }