import * as types from './mutation-types'
import * as dashboardTypes from '../dashboard/mutation-types'

export const fetchPayables = ({ commit, dispatch, state }, params) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/api/payables`, { params }).then((response) => {
            commit(types.SET_PAYABLES, response.data.payables.data)
            commit(types.SET_TOTAL_PAYABLES, response.data.payableTotalCount)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const fetchViewPayable = ({ commit, dispatch, state }, hash) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/api/payables/${hash}`).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}
export const searchPayable = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/api/payables?${data}`).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}
export const setSelectAllState = ({ commit, dispatch, state }, data) => {
    commit(types.SET_SELECT_ALL_STATE, data)
}

export const selectAllPayables = ({ commit, dispatch, state }) => {
    if (state.selectedPayables.length === state.payables.length) {
        commit(types.SET_SELECTED_PAYABLES, [])
        commit(types.SET_SELECT_ALL_STATE, false)
    } else {
        let allPayableIds = state.payables.map(inv => inv.id)
        commit(types.SET_SELECTED_PAYABLES, allPayableIds)
        commit(types.SET_SELECT_ALL_STATE, true)
    }
}

export const resetSelectedPayables = ({ commit, dispatch, state }) => {
    commit(types.RESET_SELECTED_PAYABLES)
}
export const setSupplier = ({ commit, dispatch, state }, data) => {
    commit(types.RESET_SUPPLIER)
    commit(types.SET_SUPPLIER, data)
}

export const resetSupplier = ({ commit, dispatch, state }) => {
    commit(types.RESET_SUPPLIER)
}
export const selectPayable = ({ commit, dispatch, state }, data) => {
    commit(types.SET_SELECTED_PAYABLES, data)
    if (state.selectedPayables.length === state.payables.length) {
        commit(types.SET_SELECT_ALL_STATE, true)
    } else {
        commit(types.SET_SELECT_ALL_STATE, false)
    }
}