var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      class: _vm.btnClass,
      attrs: { type: _vm.type, disabled: _vm.disabled || _vm.loading },
      on: { click: _vm.handleClick }
    },
    [
      _vm.icon && !_vm.loading && !_vm.rightIcon
        ? _c("font-awesome-icon", {
            staticClass: "vue-icon icon-left",
            class: _vm.iconClass,
            attrs: { icon: _vm.icon }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loading
        ? _c("font-awesome-icon", {
            staticClass: "fa-spin",
            class: _vm.iconClass,
            attrs: { icon: "spinner" }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _vm.icon && !_vm.loading && _vm.rightIcon
        ? _c("font-awesome-icon", {
            staticClass: "vue-icon icon-right",
            class: _vm.iconClass,
            attrs: { icon: _vm.icon }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }