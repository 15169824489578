var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table-component" },
    [
      _vm.showFilter && _vm.filterableColumnExists
        ? _c("div", { staticClass: "table-component__filter" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.filter,
                  expression: "filter"
                }
              ],
              class: _vm.fullFilterInputClass,
              attrs: { placeholder: _vm.filterPlaceholder, type: "text" },
              domProps: { value: _vm.filter },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.filter = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _vm.filter
              ? _c(
                  "a",
                  {
                    staticClass: "table-component__filter__clear",
                    on: {
                      click: function($event) {
                        _vm.filter = ""
                      }
                    }
                  },
                  [_vm._v("×")]
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table-component__table-wrapper" },
        [
          _vm.loading
            ? _c("base-loader", { staticClass: "table-loader" })
            : _vm._e(),
          _vm._v(" "),
          _c("table", { class: _vm.fullTableClass }, [
            _vm.showCaption
              ? _c(
                  "caption",
                  {
                    staticClass: "table-component__table__caption",
                    attrs: { role: "alert", "aria-live": "polite" }
                  },
                  [_vm._v(_vm._s(_vm.ariaCaption))]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("thead", { class: _vm.fullTableHeadClass }, [
              _c(
                "tr",
                _vm._l(_vm.columns, function(column) {
                  return _c("table-column-header", {
                    key: column.show || column.show,
                    attrs: { sort: _vm.sort, column: column },
                    on: { click: _vm.changeSorting }
                  })
                }),
                1
              )
            ]),
            _vm._v(" "),
            _c(
              "tbody",
              { class: _vm.fullTableBodyClass },
              _vm._l(_vm.displayedRows, function(row) {
                return _c("table-row", {
                  key: row.vueTableComponentInternalRowId,
                  attrs: { row: row, columns: _vm.columns },
                  on: { rowClick: _vm.emitRowClick }
                })
              }),
              1
            ),
            _vm._v(" "),
            _c("tfoot", [_vm._t("tfoot", null, { rows: _vm.rows })], 2)
          ])
        ],
        1
      ),
      _vm._v(" "),
      _vm.displayedRows.length === 0 && !_vm.loading
        ? _c("div", { staticClass: "table-component__message" }, [
            _vm._v(_vm._s(_vm.filterNoResults))
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticStyle: { display: "none" } }, [_vm._t("default")], 2),
      _vm._v(" "),
      _vm.pagination && !_vm.loading
        ? _c("pagination", {
            attrs: { pagination: _vm.pagination },
            on: { pageChange: _vm.pageChange }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }