var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return this.isVisible
    ? _c(
        "th",
        {
          class: _vm.headerClass,
          attrs: {
            "aria-sort": _vm.ariaSort,
            "aria-disabled": _vm.ariaDisabled,
            role: "columnheader"
          },
          on: { click: _vm.clicked }
        },
        [_vm._v("\n  " + _vm._s(_vm.label) + "\n")]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }