var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "base-switch" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.checkValue,
          expression: "checkValue"
        }
      ],
      attrs: { id: _vm.uniqueId, type: "checkbox" },
      domProps: {
        checked: Array.isArray(_vm.checkValue)
          ? _vm._i(_vm.checkValue, null) > -1
          : _vm.checkValue
      },
      on: {
        input: _vm.handleInput,
        change: [
          function($event) {
            var $$a = _vm.checkValue,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.checkValue = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.checkValue = $$a
                    .slice(0, $$i)
                    .concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.checkValue = $$c
            }
          },
          _vm.handleChange
        ],
        keyup: _vm.handleKeyupEnter,
        blur: _vm.handleFocusOut
      }
    }),
    _vm._v(" "),
    _c("label", { staticClass: "switch-label", attrs: { for: _vm.uniqueId } })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }