<template>
  <div class="invoice-index-page invoices main-content">
    <div class="page-header">
      <h3 class="page-title">{{ $tc("invoices.payable", 2) }}</h3>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link slot="item-title" to="dashboard">
            {{ $t("general.home") }}
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link slot="item-title" to="#">
            {{ $tc("invoices.payable", 2) }}
          </router-link>
        </li>
      </ol>
      <div class="page-actions row">
        <div class="col-xs-2 mr-4">
          <base-button
            v-show="totalPayables || filtersApplied"
            :outline="true"
            :icon="filterIcon"
            size="large"
            color="theme"
            right-icon
            @click="toggleFilter"
          >
            {{ $t("general.filter") }}
          </base-button>
        </div>
      </div>
    </div>

    <transition name="fade">
      <div v-show="showFilters" class="filter-section">
        <div class="filter-container">
          <div class="filter-customer">
            <label>{{ $tc("general.supppliers", 1) }} </label>
            <base-supplier-select
              :supplier="filters.supplier"
              ref="supplierSelect"
              @select="onSelectSupplier"
              @deselect="clearSupplierSearch"
            />
          </div>
          <div class="filter-status">
            <label>{{ $t("invoices.status") }}</label>
            <base-select
              v-model="filters.status"
              :options="status"
              :group-select="false"
              :searchable="true"
              :show-labels="false"
              :placeholder="$t('general.select_a_status')"
              group-values="options"
              group-label="label"
              track-by="name"
              label="name"
              @remove="clearStatusSearch()"
            />
          </div>
          <div class="filter-date">
            <div class="from pr-3">
              <label>{{ $t("general.from") }}</label>
              <base-date-picker
                v-model="filters.from_date"
                :calendar-button="true"
                calendar-button-icon="calendar"
              />
            </div>
            <div class="dashed" />
            <div class="to pl-3">
              <label>{{ $t("general.to") }}</label>
              <base-date-picker
                v-model="filters.to_date"
                :calendar-button="true"
                calendar-button-icon="calendar"
              />
            </div>
          </div>
          <div class="filter-invoice">
            <label>{{ $t("invoices.invoice_number") }}</label>
            <base-input v-model="filters.invoice_number" icon="hashtag" />
          </div>
        </div>
        <label class="clear-filter" @click="clearFilter">{{
          $t("general.clear_all")
        }}</label>
      </div>
    </transition>

    <div
      v-cloak
      v-show="showEmptyScreen"
      class="col-xs-1 no-data-info"
      align="center"
    >
      <moon-walker-icon class="mt-5 mb-4" />
      <div class="row" align="center">
        <label class="col title">{{
          $t("invoices.no_payable_invoices")
        }}</label>
      </div>
      <div class="row">
        <label class="description col mt-1" align="center">{{
          $t("invoices.list_of_payable_invoices")
        }}</label>
      </div>
    </div>

    <div v-show="!showEmptyScreen" class="table-container">
      <div class="table-actions mt-5">
        <p class="table-stats">
          {{ $t("general.showing") }}: <b>{{ payables.length }}</b>
          {{ $t("general.of") }} <b>{{ totalPayables }}</b>
        </p>

        <!-- Tabs -->
        <ul class="tabs">
          <li class="tab" @click="getStatus('UNPAID')">
            <a
              :class="[
                'tab-link',
                { 'a-active': filters.status.value === 'UNPAID' },
              ]"
              href="#"
              >{{ $t("invoices.unpaid") }}</a
            >
          </li>
          <li class="tab" @click="getStatus('PAID')">
            <a
              :class="[
                'tab-link',
                { 'a-active': filters.status.value === 'PAID' },
              ]"
              href="#"
              >{{ $t("invoices.paid") }}</a
            >
          </li>
          <li class="tab" @click="getStatus('')">
            <a
              :class="[
                'tab-link',
                {
                  'a-active':
                    filters.status.value === '' ||
                    filters.status.value === null ||
                    (filters.status.value !== 'PAID' &&
                      filters.status.value !== 'UNPAID'),
                },
              ]"
              href="#"
              >{{ $t("general.all") }}</a
            >
          </li>
        </ul>
        <transition name="fade">
          <v-dropdown v-if="selectedPayables.length" :show-arrow="false">
            <span
              slot="activator"
              href="#"
              class="table-actions-button dropdown-toggle"
            >
              {{ $t("general.actions") }}
            </span>
          </v-dropdown>
        </transition>
      </div>
      <div class="custom-control custom-checkbox">
        <input
          id="select-all"
          v-model="selectAllFieldStatus"
          type="checkbox"
          class="custom-control-input"
          @change="selectAllPayables"
        />
        <label
          v-show="!isRequestOngoing"
          for="select-all"
          class="custom-control-label selectall"
        >
          <span class="select-all-label">{{ $t("general.select_all") }} </span>
        </label>
      </div>

      <table-component
        ref="table"
        :show-filter="false"
        :data="fetchData"
        table-class="table"
      >
        <table-column
          :sortable="false"
          :filterable="false"
          cell-class="no-click"
        >
          <template slot-scope="row">
            <div class="custom-control custom-checkbox">
              <input
                :id="row.id"
                v-model="selectField"
                :value="row.id"
                type="checkbox"
                class="custom-control-input"
              />
              <label :for="row.id" class="custom-control-label" />
            </div>
          </template>
        </table-column>
        <table-column
          :label="$t('invoices.date')"
          sort-as="invoice_date"
          show="formattedInvoiceDate"
        />
        <table-column :label="$t('invoices.supplier')" width="20%">
          <template slot-scope="row">
            {{ row.company.user.name }}
          </template>
        </table-column>
        <table-column :label="$t('invoices.status')" sort-as="status">
          <template slot-scope="row">
            <span> {{ $t("invoices.status") }}</span>
            <span :class="'inv-status-' + row.status.toLowerCase()">{{
              row.status != "PARTIALLY_PAID"
                ? row.status
                : row.status.replace("_", " ")
            }}</span>
          </template>
        </table-column>
        <table-column :label="$t('invoices.paid_status')" sort-as="paid_status">
          <template slot-scope="row">
            <span>{{ $t("invoices.paid_status") }}</span>
            <span :class="'inv-status-' + row.paid_status.toLowerCase()">{{
              row.paid_status != "PARTIALLY_PAID"
                ? row.paid_status
                : row.paid_status.replace("_", " ")
            }}</span>
          </template>
        </table-column>
        <table-column :label="$t('invoices.number')" show="invoice_number" />
        <table-column :label="$t('invoices.amount_due')" sort-as="due_amount">
          <template slot-scope="row">
            <span>{{ $t("invoices.amount_due") }}</span>
            <div
              v-html="$utils.formatMoney(row.due_amount, row.user.currency)"
            />
          </template>
        </table-column>
        <table-column
          :sortable="false"
          :filterable="false"
          cell-class="action-dropdown no-click"
        >
          <template slot-scope="row">
            <span>{{ $t("invoices.action") }}</span>
            <v-dropdown>
              <a slot="activator" href="#">
                <dot-icon />
              </a>
              <v-dropdown-item>
                <router-link
                  :to="{ path: `payables/${row.unique_hash}/view` }"
                  class="dropdown-item"
                >
                  <font-awesome-icon icon="eye" class="dropdown-item-icon" />
                  {{ $t("invoices.view") }}
                </router-link>
              </v-dropdown-item>
            </v-dropdown>
          </template>
        </table-column>
      </table-component>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import MoonWalkerIcon from "../../../js/components/icon/MoonwalkerIcon";
import moment from "moment";

export default {
  components: {
    "moon-walker-icon": MoonWalkerIcon,
  },
  data() {
    return {
      showFilters: false,
      currency: null,
      status: [
        {
          label: "Status",
          isDisable: true,
          options: [
            { name: "UNPAID", value: "UNPAID" },
            { name: "PAID", value: "PAID" },
            { name: "PARTIALLY PAID", value: "PARTIALLY_PAID" },
          ],
        },
      ],
      filtersApplied: false,
      isRequestOngoing: true,
      filters: {
        supplier: "",
        status: { name: "UNPAID", value: "UNPAID" },
        from_date: "",
        to_date: "",
        invoice_number: "",
      },
    };
  },

  computed: {
    showEmptyScreen() {
      return false; //!this.totalPayables && !this.isRequestOngoing && !this.filtersApplied
    },
    filterIcon() {
      return this.showFilters ? "times" : "filter";
    },
    ...mapGetters("supplier", ["suppliers"]),
    ...mapGetters("payable", [
      "selectedPayables",
      "totalPayables",
      "payables",
      "selectAllField",
    ]),
    selectField: {
      get: function () {
        return this.selectedPayables;
      },
      set: function (val) {
        this.selectPayable(val);
      },
    },
    selectAllFieldStatus: {
      get: function () {
        return this.selectAllField;
      },
      set: function (val) {
        this.setSelectAllState(val);
      },
    },
  },
  watch: {
    filters: {
      handler: "setFilters",
      deep: true,
    },
    isRequestOngoing(newVal) {
      if (newVal) {
        if (this.selectAllField || this.selectedPayables.length > 0) {
          this.setSelectAllState(false);
          this.selectPayable([]);
        }
      }
    },
    supplier:function(newValue,oldValue) {
      console.log('Prop changed: ', newValue, ' | was: ', oldValue)
    }
  },
  created() {
    this.fetchSuppliers();
  },
  destroyed() {
    if (this.selectAllField) {
      this.selectAllPayables();
    }
  },
  methods: {
    ...mapActions("payable", [
      "fetchPayables",
      "selectPayable",
      "resetSelectedPayables",
      "selectAllPayables",
      "setSelectAllState",
    ]),
    ...mapActions("supplier", ["fetchSuppliers"]),

    getStatus(val) {
      this.filters.status = {
        name: val,
        value: val,
      };
    },
    refreshTable() {
      this.$refs.table.refresh();
    },
    async fetchData({ page, filter, sort }) {
      let data = {
        company_id:
          this.filters.supplier === ""
            ? this.filters.supplier
            : this.filters.supplier.company_id,
        status: this.filters.status.value,
        from_date:
          this.filters.from_date === ""
            ? this.filters.from_date
            : moment(this.filters.from_date).format("DD/MM/YYYY"),
        to_date:
          this.filters.to_date === ""
            ? this.filters.to_date
            : moment(this.filters.to_date).format("DD/MM/YYYY"),
        invoice_number: this.filters.invoice_number,
        orderByField: sort.fieldName || "created_at",
        orderBy: sort.order || "desc",
        page,
      };
      if (this.$route.query.user != null ) {
        this.showFilters=true;
        this.filtersApplied=true;
        this.onSelectSupplier(this.$route.query.user);
        this.$route.query.user=null;
      }
      this.isRequestOngoing = true;
      let response = await this.fetchPayables(data);
      this.isRequestOngoing = false;

      this.currency = response.data.currency;

      return {
        data: response.data.payables.data,
        pagination: {
          totalPages: response.data.payables.last_page,
          currentPage: page,
          count: response.data.payables.count,
        },
      };
    },
    setFilters() {
      this.filtersApplied = true;
      this.resetSelectedPayables();
      this.refreshTable();
    },
    clearFilter() {
      if (this.filters.supplier) {
        this.$refs.supplierSelect.$refs.baseSelect.removeElement(
          this.filters.supplier
        );
      }
      this.filters = {
        supplier: "",
        status: "",
        from_date: "",
        to_date: "",
        invoice_number: "",
      };

      this.$nextTick(() => {
        this.filtersApplied = false;
      });
    },
    toggleFilter() {
      if (this.showFilters && this.filtersApplied) {
        this.clearFilter();
        this.refreshTable();
      }

      this.showFilters = !this.showFilters;
    },
    onSelectSupplier(supplier) {
      this.filters.supplier = supplier;
    },

    async clearSupplierSearch(removedOption, id) {
      this.filters.supplier = "";
      this.refreshTable();
    },
    async clearStatusSearch(removedOption, id) {
      this.filters.status = "";
      this.refreshTable();
    },
  },
};
</script>
