<template>
  <div class="main-content">
    <form action="" @submit.prevent="sendData">
      <div class="page-header">
        <h3 class="page-title">{{$t('suppliers.invite_supplier') }}</h3>
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><router-link slot="item-title" to="/admin/dashboard">{{ $t('general.home') }}</router-link></li>
          <li class="breadcrumb-item"><router-link slot="item-title" to="/admin/suppliers">{{ $tc('suppliers.supplier', 2) }}</router-link></li>
          <li class="breadcrumb-item"><a href="#">{{ $t('suppliers.invite_supplier') }}</a></li>
        </ol>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="form-group col-sm-4">
                  <label class="control-label">{{ $t('suppliers.email') }}</label><span class="text-danger"> * </span>
                    <base-input
                      placeholder="Invitee Email"
                      v-model.trim="formData.email"
                      :invalid="$v.formData.email.$error"
                      focus
                      type="email"
                      name="email"
                    />
                    <div v-if="$v.formData.email.$error">
                      <span v-if="!$v.formData.email.required" class="text-danger">{{ $t('validation.required') }} </span>
                    </div>
                </div>
                <div class="col-sm-12">
                  <div class="form-group collapse-button-container">
                    <base-button
                      :loading="isLoading"
                      :disabled="isLoading"
                      icon="paper-plane"
                      color="theme"
                      type="submit"
                      class="collapse-button"
                    >
                      {{ $t('suppliers.invite') }}
                    </base-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
const { required} = require('vuelidate/lib/validators')

export default {
  data () {
    return {
      isLoading: false,
      formData: {
        email: null,
      },
      fileSendUrl: '/api/suppliers'
    }
  },
  validations: {
    formData: {
      email: {
        required,
      },
    }
  },
  methods: {
    ...mapActions('supplier', [
      'inviteSupplier',
    ]),
    async sendData () {
      this.isLoading = true;
      this.$v.formData.$touch()
      let data = new FormData()
      data.append('email', (this.formData.email))
      let response = await this.inviteSupplier(data)
      if (response.data.success) {
        this.isLoading = false;
        window.toastr['success'](this.$t('suppliers.invited'))
        this.$router.push('/admin/suppliers')
        return true
      }
      else{
        this.isLoading = false;
        window.toastr['error'](response.data.error)
      }
    }
  }
}
</script>
