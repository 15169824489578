import * as types from './mutation-types'

export default {
    [types.SET_PAYABLES](state, payables) {
        state.payables = payables
    },

    [types.SET_TOTAL_PAYABLES](state, payableTotalCount) {
        state.totalPayables = payableTotalCount
    },

    [types.SET_SELECTED_PAYABLES](state, data) {
        state.selectedPayables = data
    },

    [types.RESET_SELECTED_PAYABLES](state, data) {
        state.selectedPayables = []
        state.selectAllField = false
    },


    [types.SELECT_SUPPLIER](state, data) {
        state.selectedSupplier = data
    },

    [types.RESET_SELECTED_SUPPLIER](state, data) {
        state.selectedSupplier = null
    },

    [types.SET_SELECT_ALL_STATE](state, data) {
        state.selectAllField = data
    }
}
