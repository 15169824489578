var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "40",
        height: "39",
        viewBox: "0 0 40 39",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("circle", {
        attrs: {
          cx: "20.0078",
          cy: "19.7782",
          r: "18.7188",
          fill: "#F2F2F2",
          stroke: "#F2F2F2",
          "stroke-width": "1.00639"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M13.744 21.9871L12.9733 24.8641L10.1565 24.9237C9.31465 23.3623 8.83716 21.5759 8.83716 19.6775C8.83716 17.8418 9.2836 16.1107 10.075 14.5864H10.0756L12.5833 15.0462L13.6819 17.5389C13.4519 18.2092 13.3266 18.9288 13.3266 19.6775C13.3267 20.4902 13.4739 21.2688 13.744 21.9871Z",
          fill: "#FBBB00"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M30.7842 17.6089C30.9114 18.2786 30.9777 18.9701 30.9777 19.677C30.9777 20.4695 30.8943 21.2426 30.7356 21.9883C30.1967 24.526 28.7886 26.7419 26.8379 28.3099L26.8373 28.3093L23.6786 28.1482L23.2316 25.3575C24.5259 24.5984 25.5375 23.4104 26.0703 21.9883H20.1508V17.6089H26.1567H30.7842Z",
          fill: "#518EF8"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M26.8374 28.3096L26.838 28.3102C24.9409 29.8351 22.531 30.7475 19.9076 30.7475C15.6918 30.7475 12.0264 28.3911 10.1566 24.9235L13.7441 21.9868C14.679 24.4819 17.0859 26.258 19.9076 26.258C21.1204 26.258 22.2567 25.9301 23.2317 25.3578L26.8374 28.3096Z",
          fill: "#28B446"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M26.9737 11.1555L23.3874 14.0916C22.3783 13.4608 21.1855 13.0964 19.9075 13.0964C17.022 13.0964 14.5701 14.954 13.682 17.5386L10.0757 14.5861H10.0751C11.9175 11.0339 15.6291 8.60693 19.9075 8.60693C22.5936 8.60693 25.0564 9.56373 26.9737 11.1555Z",
          fill: "#F14336"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }